import React, { useState } from 'react';

import RSMBlueCheck from '../../../../../assets/check-blue-rsm.svg';
import BlueCheck from '../../../../../assets/check-blue.svg';
import MultiRSM from '../../../../../assets/choose-plan-multi-company-rsm.svg';
import Multi from '../../../../../assets/choose-plan-multi-company.svg';
import SingleRSM from '../../../../../assets/choose-plan-single-company-rsm.svg';
import Single from '../../../../../assets/choose-plan-single-company.svg';
import { isEnvRSM } from '../../../../../common/utilities/_helpers';

import './ChoosePlanCard.scss';

const isRSM = isEnvRSM();

interface PlanVariantConfig {
  type: string;
  heading: string;
  icon: any;
  subheading: string;
  cta: string;
}

const SingleVariant: PlanVariantConfig = {
  type: 'single',
  heading: 'Single Company',
  icon: isRSM ? SingleRSM : Single,
  subheading: 'Manage leases for one company',
  cta: 'Start free with 2 leases',
};

const MultiVariant: PlanVariantConfig = {
  type: 'multi',
  heading: 'Multi-Company',
  icon: isRSM ? MultiRSM : Multi,
  subheading: 'Manage leases for up to 5 companies',
  cta: 'Start free with 1 company, 2 leases',
};

const singlePlanRSMOverrides = {
  leaseRange: ' 1-20',
  priceRange: ' up to 20 leases.',
};

export const ChoosePlanCardVariant = {
  CONTRIBUTING: SingleVariant,
  MULTI: MultiVariant,
};

export interface ChoosePlanCardProps {
  variant: PlanVariantConfig;
  selected: boolean;
  onClick: () => void;
}

export default function ChoosePlanCard({
  variant,
  onClick,
  selected,
}: ChoosePlanCardProps) {
  const [onHover, setOnHover] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      onClick();
    }
  };

  const buildSinglePlanText = () => {
    return (
      <>
        For companies looking to manage their own lease portfolio of
        {isRSM ? singlePlanRSMOverrides.leaseRange : ' 1-15'} leases.
        <br />
        <br />
        Always free at 2 leases.
        <br />
        Have a larger lease portfolio? Premium plans are available for
        {isRSM ? singlePlanRSMOverrides.priceRange : ' up to 15 leases.'}
      </>
    );
  };

  return (
    <div
      className={`choose-plan-card-wrapper ${selected && 'selected'}`}
      tabIndex={1}
      onClick={onClick}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      onKeyDown={(e) => handleKeyDown(e)}
      onFocus={() => setOnHover(true)}
      onBlur={() => setOnHover(false)}
    >
      <img
        className={`plan-check ${selected ? 'selected' : ''}`}
        alt="plan check"
        src={isRSM ? RSMBlueCheck : BlueCheck}
      />

      <div className="plan-icon">
        <img src={variant.icon} alt="single-company" />
      </div>
      <div className="plan-type-heading">{variant.heading}</div>
      <div className="plan-type-subheading">{variant.subheading}</div>
      <div className={`plan-cta ${selected && 'selected'}`}>{variant.cta}</div>

      {variant.type === 'single' && (
        <div className={`tool-tip-left ${onHover && 'hover'}`}>
          <div className="tool-tip-content">
            <div className="tooltip-header">Single Company Plan</div>
            <div className="tooltip-body">{buildSinglePlanText()}</div>
          </div>
        </div>
      )}

      {variant.type === 'multi' && (
        <div className={`tool-tip-right ${onHover && 'hover'}`}>
          <div className="tool-tip-content">
            <div className="tooltip-header">Multi-Company Plan</div>
            <div className="tooltip-body">
              Ideal for CPA firms and other Accounting firms looking to manage
              lease portfolios for multiple companies or clients.
              <br />
              <br />
              Enter leases and manage the data for the companies you are
              handling. Clients can be added as a guest (read-only) user to
              their company account to view data and journal entries.
              <br />
              <br />
              Start for free with one company, 2 leases. Then upgrade your plan
              to accommodate multiple companies and up to 10 leases.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
