import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal, showModal } from '../../../common/modal/actions';
import { useResourceContext } from '../../../common/resource-context';
import { IStore } from '../../../store';
import { findNextAvailableUpgrade } from '../../plans/helpers';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';

import './UpgradeFooter.scss';

const UpgradeFooter = () => {
  const dispatch = useDispatch();
  const resources = useResourceContext();

  const multiClientPlans = useSelector(
    (store: IStore) => store.multiClientPlans,
  );
  const currentPlan = useSelector((store: IStore) => store.user.company.plan!);

  const nextPlan = findNextAvailableUpgrade(multiClientPlans, currentPlan);

  const showCheckoutModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: nextPlan?.features,
            initialSelectedPlan: nextPlan,
          },
        },
      }),
    );
  };

  const navigateToEnterprise = () => {
    const enterpriseUrl = resources.text('EnterprisePricingUrl');
    window.open(enterpriseUrl, '_blank');
  };

  const UPGRADE_CONFIG = {
    basic: {
      main_text: `Ready to purchase your LeaseGuru Multi-Company plan?`,
      button_text: ' Buy Now.',
      button_click: showCheckoutModal,
    },
    premium: {
      main_text: 'Need to add more companies?',
      button_text: ' Upgrade your account.',
      button_click: showCheckoutModal,
    },
    enterprise: {
      main_text: 'Need more companies or leases?',
      button_text: ' Talk to us about LeaseQuery.',
      button_click: navigateToEnterprise,
    },
  };

  const { main_text, button_click, button_text } =
    currentPlan.tier === 'Basic'
      ? UPGRADE_CONFIG.basic
      : nextPlan
      ? UPGRADE_CONFIG.premium
      : UPGRADE_CONFIG.enterprise;

  return (
    <div className="multi-client-footer">
      {main_text}
      <button className="button-link" type="button" onClick={button_click}>
        {button_text}
      </button>
    </div>
  );
};

export default UpgradeFooter;
