import React, { FunctionComponent } from 'react';
import { useResourceContext } from '../../../common/resource-context';
import { IPlanModel } from '../../../interfaces';
import { isCurrentPlan } from '../helpers/helpers';
import './PlansTable.scss';
import './PremiumPlanHeader.scss';

interface IPremiumPlanHeaderProps {
  planModels: IPlanModel[];
  userCurrentPlan?: IPlanModel;
  checkOut: (planName: string) => void;
}

const PremiumPlanHeader: FunctionComponent<IPremiumPlanHeaderProps> = ({
  planModels,
  userCurrentPlan,
  checkOut,
}) => {
  const maxPremiumPlan = 'CPA Plan 5';
  const currentPlanPrice = userCurrentPlan ? userCurrentPlan.price : 0;
  const getPlanPrice = (name: string): number => {
    const plan = planModels.find((p) => p.name.endsWith(name));
    return plan !== undefined ? plan.price : 0;
  };

  const checkOutHandler = (planName: string) => {
    const isCurrent: boolean = isCurrentPlan(
      planName,
      planModels,
      userCurrentPlan,
    );
    if (!isCurrent) {
      checkOut(planName);
    }
  };

  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  const displayCheckOutButton = (planName: string) => {
    const planPrice = getPlanPrice(planName);
    const isCurrent: boolean = isCurrentPlan(
      planName,
      planModels,
      userCurrentPlan,
    );
    return planPrice > currentPlanPrice ? (
      <div className="button">
        <button
          disabled={isCurrent}
          onClick={() => {
            checkOutHandler(planName);
          }}
        >
          <span>upgrade</span>
        </button>
      </div>
    ) : null;
  };

  const resources = useResourceContext();
  const pricePerClient = resources.text('MultiClientPricePerClient');

  return (
    <div className={`${isRSM && 'rsm'}`}>
      <div className="plan-wrapper">
        <div className="card-title">FULL</div>
        <div className="card-content">
          <div className="card">
            <div className="lease-count" />
            <div className="cost">
              ${pricePerClient}
              <div>starting at</div>
            </div>
            {displayCheckOutButton(maxPremiumPlan)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPlanHeader;
